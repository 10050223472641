.about-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin: 4rem auto;
  width: 90%;
  max-width: 1000px;
}

.about-section {
  padding: 2rem;
  transition: background-color 0.3s ease;
}

.about-section:hover {
  background: #f9fafb;
}

.about-section h1 {
  color: #105b63;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  position: relative;
}

.about-section h1::after {
  content: "";
  display: block;
  width: 50px;
  height: 3px;
  background: #105b63;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.about-section p {
  text-align: start;
  font-size: 1.2rem;
  line-height: 1.6;
  text-indent: 2em;
}

.about-section p:first-of-type::first-line {
  text-indent: 0;
}

.about-section ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

.about-section li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 8px;
  font-size: 1.2rem;
  line-height: 1.6;
}

.about-section li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #105b63;
  font-size: 1.2rem;
}

@media screen and (max-width: 850px) {
  .about-container {
    margin: 4rem 1rem;
  }

  .about-section {
    padding: 1.5rem;
  }

  .about-section h1 {
    font-size: 1.8rem;
  }
}
