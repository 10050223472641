.works-container {
    display: flex;
    justify-content: space-between;
    margin: 4rem auto;
    width: 90%;
    max-width: 1000px;
}

.works-text {
    flex-basis: calc(100% - 340px);
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border: 1px solid #105b6333;
    transition: background-color 0.3s ease;
}

.works-text:hover {
    background-color: #f9fafb;
}

.works-image {
    flex-basis: 300px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.works-img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.works-text h1 {
    text-align: start;
    margin-bottom: 20px;
    font-size: 1.6rem;
    color: #105b63;
}

.works-text p {
    text-align: start;
    font-size: 1.2rem;
    line-height: 1.6;
}

@media screen and (max-width: 850px) {
    .works-container {
        flex-direction: column;
        align-items: center;
    }
    
    .works-image,
    .works-text {
        flex-basis: auto;
        width: 100%;
    }
    
    .works-image {
        margin-bottom: 20px;
    }
}

/* Accordion Section */
.accordion-section {
    margin: 4rem auto;
    width: 90%;
    max-width: 1000px;
}

.accordion-toggle {
    background-color: #105b63;
    color: white;
    border: none;
    padding: 15px 20px;
    cursor: pointer;
    font-size: 1.2rem;
    width: 100%;
    text-align: left;
    border-radius: 10px;
    transition: background-color 0.3s ease;
}

.accordion-toggle:hover {
    background-color: #0d4b4d;
}

.accordion-toggle:focus {
    outline: none;
}

.additional-text {
    padding: 20px;
    background-color: #f9fafb;
    border: 1px solid #105b6333;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    transition: max-height 0.3s ease;
    overflow: hidden;
}

/* Add transition for expand/collapse effect */
.additional-text.collapsed {
    max-height: 0;
    padding: 0;
}

.additional-text-list{
    list-style-type: none;
    padding: 0;
    text-align: left;
}

.additional-text-list li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
    line-height: 1.6;
}

.additional-text-list li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: #105b63;
    font-size: 1.2rem;
  }