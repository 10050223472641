.Map {
    margin: 4rem auto;
    display: flex;
    align-items: center;
    text-align: start;
    justify-content: space-between;
    width: 90%;
    max-width: 1000px;
}

.contact-col{
    flex-basis: 48%;
    color: #676767;
}

.contact-col h3{
    color: #105b63;
    font-weight: 500;
    font-size: 2.2rem;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.contact-col p{
    max-width: 450px;
    line-height: 0.3;
}

.contact-col ul li{
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.contact-col i{
    padding-right: 10px;
}

iframe{
    width: 100%;
    height: 500px;
    
}

@media screen and (max-width: 910px){
    .Map {
        display: block;
    }

    .contact-col{
        padding: 20px;
    }
}