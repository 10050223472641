/* Общие стили контейнера */
.sugar-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin: 4rem auto;
  width: 90%;
  max-width: 1000px;
}

/* Стили текста */
.sugar-text {
  padding: 2rem;
  transition: background-color 0.3s ease;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #105b6333;
}

.sugar-text:hover {
  background: #f9fafb;
}

/* Заголовок текста */
.sugar-text h1 {
  color: #105b63;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  position: relative;
}

.sugar-text h1::after {
  content: "";
  display: block;
  width: 50px;
  height: 3px;
  background: #105b63;
  position: absolute;
  bottom: -10px;
  left: 0;
}

/* Подзаголовок */
.sugar-text h3 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: #105b63;
  position: relative;
}

.sugar-text h3::after {
  content: "";
  display: block;
  width: 50px;
  height: 3px;
  background: #105b63;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.sugar-text h4 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #105b63;
}

/* Основной текст */
.sugar-text p {
  text-align: start;
  font-size: 1.2rem;
  line-height: 1.6;
  text-indent: 2em;
}

.sugar-text p:first-of-type::first-line {
  text-indent: 0;
}

/* Стили списка */
.sugar-text ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

.sugar-text li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 8px;
  font-size: 1.2rem;
  line-height: 1.6;
}

.sugar-text li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #105b63;
  font-size: 1.2rem;
}

/* Стили изображения */
.sugar-image {
  border-radius: 10px;
  overflow: hidden;
}

.sugar-img {
  width: 100%;
  height: 300px;
  display: block;
  object-fit: cover;
}

/* Адаптивные стили для мобильных устройств */
@media screen and (max-width: 850px) {
  .sugar-container {
    margin: 4rem 1rem;
  }

  .sugar-text {
    padding: 1.5rem;
  }

  .sugar-text h1 {
    font-size: 1.8rem;
  }

  .sugar-text p {
    font-size: 1rem;
    text-indent: 1.5em;
  }

  .sugar-text ul {
    padding-left: 1rem;
  }

  .sugar-text li {
    padding-left: 15px;
    font-size: 1rem;
  }

  .sugar-text li::before {
    font-size: 1rem;
  }

  .sugar-img {
    height: 200px;
  }
}