.servicelist {
    display: flex;
    justify-content: space-between;
    margin: 4rem auto;
    width: 90%;
    max-width: 1000px;
}

.servicelist-text {
    flex-basis: calc(100% - 240px);
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border: 1px solid #105b6333;
    transition: background-color 0.3s ease;
    position: relative;
}

.servicelist-text:hover {
    background-color: #f9fafb;
}

.servicelist-image {
    flex-basis: 200px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    height: 150px;
}

.servicelist-img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.servicelist-text h1 {
    text-align: start;
    margin-bottom: 20px;
    font-size: 1.3rem;
    color: #105b63;
    position: relative;
    cursor: pointer;
    padding-right: 30px;
}

.servicelist-text p {
    text-align: justify;
    font-size: 1.1rem;
    padding-bottom: 1.5rem;
    line-height: 1.6;
    cursor: pointer;
}

.accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.accordion-content.open {
    max-height: 2000px;
    transition: max-height 0.2s ease-in;
}

.servicelist-text .accordion-icon {
    position: absolute;
    bottom: 10px;
    left: 50%; /* Помещаем по середине горизонтально */
    transform: translateX(-50%); /* Центрируем по горизонтали */
    font-size: 20px;
    transition: transform 0.2s ease-in-out, top 0.2s ease-in-out;
    cursor: pointer;
    color: #105b63;
    opacity: 0.7; /* Добавляем прозрачность */
    transform-origin: center; /* Устанавливаем центральную точку преобразования */
}

.servicelist-text .accordion-icon:hover {
    transform: scale(1.1) translateX(-45%); /* Немного увеличиваем и смещаем влево при наведении */
    opacity: 1; /* Увеличиваем прозрачность при наведении */
}

.accordion-content.open + .servicelist-text .accordion-icon {
    top: auto;
    bottom: 10px;
    transform: rotate(180deg);
}

.service-list {
    list-style-type: none;
    padding: 0;
    text-align: left;
}

.service-list li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
    line-height: 1.6;
}

.service-list li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: #105b63;
    font-size: 1.2rem;
  }
@media screen and (max-width: 850px) {
    .servicelist {
        flex-direction: column;
        align-items: center;
    }

    .servicelist-image,
    .servicelist-text {
        flex-basis: auto;
        width: 100%;
    }

    .servicelist-image {
        margin-bottom: 20px;
    }
}
