.mainhead {
    margin: 4rem auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    max-width: 1000px;
}

.mainhead h1{
    margin-bottom: 20px;
    text-align: start;
    font-size: 2.2rem;
    color: #105b63;
}

.mainhead p{
    text-align: start;
    font-size: 1.1rem;
    padding-bottom: 2rem;
}

.mainhead-right {
    flex-basis: 40%;
}

.mainhead-left {
    flex-basis: 56%;
}

.mainhead-img {
    width: 100%;
    border-radius: 6px;
    display: block;
}

@media screen and (max-width: 850px){
    .mainhead {
        flex-direction: column;
    }

    .mainhead-right{
        flex-basis: 100%;
        margin: 20px;
    }

    .mainhead-left{
        flex-basis: 100%;
    }
}